import { useNavigate } from '@remix-run/react'
import { ButtonAsLink } from '../Button'
import * as styles from './backButton.styles'

type BackButtonProps = {
  to?: string
}

export const BackButton: React.FC<BackButtonProps> = ({ to }) => {
  const navigate = useNavigate()
  return (
    <div css={styles.backButtonContainer}>
      <ButtonAsLink
        css={styles.backButton}
        to={to ?? ''}
        onClick={(event) => {
          event.preventDefault()
          navigate(-1)
        }}
      >
        <i className="fas fa-arrow-left" /> Back
      </ButtonAsLink>
    </div>
  )
}

export default BackButton
