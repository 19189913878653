/* eslint-disable */
import * as Types from '../types.gen'

import { gql } from '@apollo/client/index.js'
import { UserDetailsFragmentDoc } from '../fragments/userDetails.fragment.gen'
import * as Apollo from '@apollo/client/index.js'
const defaultOptions = {} as const
export type LoginMutationVariables = Types.Exact<{
  loginInput: Types.LoginInput
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  login?: {
    __typename?: 'LoginResult'
    ok: boolean
    user?: {
      __typename?: 'User'
      id: string
      name: string
      picture?: string | null
      slug: string
      email: string
      csrfToken?: string | null
      city: { __typename?: 'City'; id: number; name: string }
      selectedProfile?:
        | {
            __typename: 'Artist'
            id: string
            name: string
            description: string
            picture?: string | null
            slug: string
            genres: Array<{ __typename?: 'Genre'; id: number; name: string }>
            links: Array<{ __typename?: 'Link'; name: string; url: string }>
            eventInvitations: Array<{
              __typename?: 'EventInvitation'
              eventId: string
              eventSlug: string
              token: string
              status: Types.EventInvitationStatus
              senderProfile:
                | {
                    __typename?: 'Artist'
                    id: string
                    name: string
                    slug: string
                    picture?: string | null
                  }
                | {
                    __typename?: 'Promoter'
                    id: string
                    name: string
                    slug: string
                    picture?: string | null
                  }
                | { __typename?: 'User' }
                | {
                    __typename?: 'Venue'
                    id: string
                    name: string
                    slug: string
                    picture?: string | null
                  }
            }>
          }
        | {
            __typename: 'Promoter'
            id: string
            name: string
            description: string
            picture?: string | null
            slug: string
            links: Array<{ __typename?: 'Link'; name: string; url: string }>
            eventInvitations: Array<{
              __typename?: 'EventInvitation'
              eventId: string
              eventSlug: string
              token: string
              status: Types.EventInvitationStatus
              senderProfile:
                | {
                    __typename?: 'Artist'
                    id: string
                    name: string
                    slug: string
                    picture?: string | null
                  }
                | {
                    __typename?: 'Promoter'
                    id: string
                    name: string
                    slug: string
                    picture?: string | null
                  }
                | { __typename?: 'User' }
                | {
                    __typename?: 'Venue'
                    id: string
                    name: string
                    slug: string
                    picture?: string | null
                  }
            }>
          }
        | {
            __typename: 'User'
            id: string
            name: string
            slug: string
            picture?: string | null
          }
        | {
            __typename: 'Venue'
            id: string
            name: string
            description: string
            picture?: string | null
            address: string
            slug: string
            audienceCapacity?: number | null
            ageRestriction?: Types.AgeRestriction | null
            city: { __typename?: 'City'; id: number; name: string }
            links: Array<{ __typename?: 'Link'; name: string; url: string }>
            eventInvitations: Array<{
              __typename?: 'EventInvitation'
              eventId: string
              eventSlug: string
              token: string
              status: Types.EventInvitationStatus
              senderProfile:
                | {
                    __typename?: 'Artist'
                    id: string
                    name: string
                    slug: string
                    picture?: string | null
                  }
                | {
                    __typename?: 'Promoter'
                    id: string
                    name: string
                    slug: string
                    picture?: string | null
                  }
                | { __typename?: 'User' }
                | {
                    __typename?: 'Venue'
                    id: string
                    name: string
                    slug: string
                    picture?: string | null
                  }
            }>
          }
        | null
      profiles: Array<
        | {
            __typename: 'Artist'
            id: string
            name: string
            description: string
            picture?: string | null
            slug: string
            genres: Array<{ __typename?: 'Genre'; id: number; name: string }>
            links: Array<{ __typename?: 'Link'; name: string; url: string }>
          }
        | {
            __typename: 'Promoter'
            id: string
            name: string
            description: string
            picture?: string | null
            slug: string
            links: Array<{ __typename?: 'Link'; name: string; url: string }>
          }
        | {
            __typename: 'User'
            id: string
            name: string
            slug: string
            picture?: string | null
          }
        | {
            __typename: 'Venue'
            id: string
            name: string
            description: string
            picture?: string | null
            address: string
            slug: string
            audienceCapacity?: number | null
            ageRestriction?: Types.AgeRestriction | null
            city: { __typename?: 'City'; id: number; name: string }
            links: Array<{ __typename?: 'Link'; name: string; url: string }>
          }
      >
    } | null
    error?: {
      __typename?: 'LoginError'
      code: Types.LoginErrorCode
      description: string
    } | null
  } | null
}

export const LoginDocument = gql`
  mutation login($loginInput: LoginInput!) {
    login(input: $loginInput) {
      ok
      user {
        ...UserDetails
      }
      error {
        code
        description
      }
    }
  }
  ${UserDetailsFragmentDoc}
`
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      loginInput: // value for 'loginInput'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  )
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>
