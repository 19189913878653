import { css } from '@emotion/react'
import { mTheme } from '../../../../style/themes'

let { size, fontColor, pageWidth } = mTheme

export const container: Style = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  alignItems: 'left',
  maxWidth: pageWidth.L,
})

export const terms: Style = css({
  marginLeft: size.S,
  marginRight: size.S,
  '&, &:hover, &:visited': {
    color: fontColor.plain.secondary,
  },
})

export const logOutButton: Style = css({
  width: 'fit-content',
  paddingLeft: size.M,
  paddingRight: size.M,
})
