export const backButton: Style = ({
  backgroundColor,
  fontColor,
  breakpoint,
}) => ({
  [breakpoint.small]: {
    width: '100%',
    backgroundColor: backgroundColor.secondary,
    color: fontColor.plain.secondary,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: backgroundColor.innerCard,
      color: fontColor.plain.primary,
    },
  },
})

export const backButtonContainer: Style = () => ({
  width: '100%',
  justifyContent: 'flex-start',
})
