import BackButton from '../../components/BackButton/BackButton'
import { displayOnSmallOnly } from '../../style/displayOnlyMobile'
import { css } from '@emotion/react'
import { Spacer } from '../../components/Spacer'

let style = css({
  width: '100%',
})

export function SettingsBackButton() {
  return (
    <div css={[displayOnSmallOnly, style]}>
      <BackButton css={displayOnSmallOnly} to="/settings" />
      <Spacer y="S" />
    </div>
  )
}
