/* eslint-disable */
import * as Types from '../types.gen'

import { gql } from '@apollo/client/index.js'
import * as Apollo from '@apollo/client/index.js'
const defaultOptions = {} as const
export type LogOutMutationVariables = Types.Exact<{ [key: string]: never }>

export type LogOutMutation = { __typename?: 'Mutation'; logOut: boolean }

export const LogOutDocument = gql`
  mutation logOut {
    logOut
  }
`
export type LogOutMutationFn = Apollo.MutationFunction<
  LogOutMutation,
  LogOutMutationVariables
>

/**
 * __useLogOutMutation__
 *
 * To run a mutation, you first call `useLogOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logOutMutation, { data, loading, error }] = useLogOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogOutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogOutMutation,
    LogOutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LogOutMutation, LogOutMutationVariables>(
    LogOutDocument,
    options,
  )
}
export type LogOutMutationHookResult = ReturnType<typeof useLogOutMutation>
export type LogOutMutationResult = Apollo.MutationResult<LogOutMutation>
export type LogOutMutationOptions = Apollo.BaseMutationOptions<
  LogOutMutation,
  LogOutMutationVariables
>
