import { ApolloClient } from '@apollo/client/index.js'
import { useApolloClient } from '@apollo/client/react/hooks'
import { useNavigate, useParams } from '@remix-run/react'

import { useCurrentUser } from '../graphql/hooks'
import { getProfileSettingsUrlFromSlug } from '../pages/Settings/settingsRoutes'

import { Artist, Profile } from '../graphql/types.gen'
import { UserProfile } from '../graphql/fragments/userDetails.fragment'
import { LogOutDocument } from '../graphql/mutations/logOut.mut.gen'
import { EventDetailsFragment } from '../graphql/fragments/eventDetails.fragment.gen'
import { CurrentUserQuery } from '../graphql/queries/user/currentUser.query.gen'
import {
  LoginMutationHookResult,
  useLoginMutation,
} from '../graphql/mutations/login.mut.gen'
import loginMut from '../graphql/mutations/login.mut'

export function isArtistEventParticipant(
  artistId: Artist['id'],
  event: EventDetailsFragment,
): boolean {
  return event.artists.some((artist) => artist.id === artistId)
}

export const checkIsSelectedProfile = (
  profileId: Profile['id'] | null | undefined,
  profileType: Profile['__typename'] | null | undefined,
  user: CurrentUserQuery['currentUser'] | null | undefined,
): boolean => {
  if (!user || !profileId || !profileType) {
    return false
  }

  if (
    user.selectedProfile?.__typename === profileType &&
    user.selectedProfile?.id === profileId
  ) {
    return true
  }

  return false
}

const userUrlPrefixes: Record<UserProfile['__typename'], string> = {
  Artist: '/artists/',
  Venue: '/venues/',
  Promoter: '/promoters/',
  User: '/profile/',
}

export function getProfileUrl(
  profile: Pick<UserProfile, '__typename' | 'slug'>,
): string {
  const profileType = profile.__typename

  if (!profileType) {
    throw new Error('Missing profile type')
  }

  return `${userUrlPrefixes[profileType]}${profile.slug}`
}

export function getProfileSettingsUrl(profile: UserProfile): string {
  const profileType = profile.__typename

  if (!profileType) {
    throw new Error('Missing profile type')
  }

  return getProfileSettingsUrlFromSlug(profile.slug)
}

export function useProfileBySlugFromParams() {
  const params = useParams()
  const { data } = useCurrentUser()

  const profile = data?.currentUser?.profiles.find(
    (profile) => profile.slug === params.slug,
  )

  return profile
}

export interface LogOutOptions {
  client: ApolloClient<object>
  shouldNotReload?: boolean
  skipMutation?: boolean
}
export async function logOut({
  client,
  shouldNotReload,
  skipMutation,
}: LogOutOptions) {
  if (!skipMutation) {
    // TODO: fix endless 401 loop
    await client.mutate({
      mutation: LogOutDocument,
    })
  }

  localStorage.clear()
  await client.resetStore()

  if (!shouldNotReload) {
    if (window.location.pathname !== '/login') {
      // navigate('/login')
    }
  }
}

export function useLogOut(options?: Omit<LogOutOptions, 'client'>) {
  const client = useApolloClient()
  let navigate = useNavigate()

  return () => {
    logOut({ ...options, client }).then(() => {
      navigate('/login')
    })
  }
}

export function useLogIn(): LoginMutationHookResult {
  let [mutate, result] = useLoginMutation()
  let navigate = useNavigate()

  let logIn: typeof mutate = async (options) => {
    return mutate({
      mutation: loginMut,
      variables: options?.variables,
      refetchQueries: ['currentUser'],
      update: (cache, result) => {
        // cache.writeQuery({
        //   query: currentUserQuery,
        //   data: {
        //     currentUser: result.data?.login?.user,
        //   },
        // })

        if (result.data?.login?.ok) {
          navigate('/')
        }
      },
    })
  }

  return [logIn, result]
}
