import Button from '../../../../components/Button'
import { useCurrentUser } from '../../../../graphql/hooks'
import { useLogOut } from '../../../../utilities/userUtils'
import * as styles from './ManageAccount.styles'

export const SessionSummary = () => {
  const { user } = useCurrentUser()
  const logOut = useLogOut()

  return (
    <section>
      <h3>Session</h3>
      <p>
        You're logged in as user: <b>{user?.name}</b>, with email:{' '}
        <b>{user?.email}</b>.
      </p>

      <Button css={styles.logOutButton} onClick={() => logOut()}>
        Log Out
      </Button>
    </section>
  )
}
