import gql from 'graphql-tag'
import userDetailsFragment from '../fragments/userDetails.fragment'

export default gql`
  mutation login($loginInput: LoginInput!) {
    login(input: $loginInput) {
      ok
      user {
        ...UserDetails
      }
      error {
        code
        description
      }
    }
  }

  ${userDetailsFragment}
`
